export const countRemainPromotion = ({
  remainQuantity,
  startDate,
  endDate
}) => {
  endDate = new Date(endDate);
  startDate = new Date(startDate);
  const promotionSlotsPerDay =
    Number(remainQuantity) /
    ((endDate.getTime() - startDate.getTime()) / (24 * 3600 * 1000));
  const remainDays = (endDate.getTime() - Date.now()) / (24 * 3600 * 1000);
  let remainSlot = 0;
  remainSlot = Math.floor(remainDays * promotionSlotsPerDay);

  return {
    remainSlot: remainSlot > 0 ? remainSlot : 0
  };
};
