import { isBrowser } from 'react-use/lib/misc/util';
import * as React from 'react';
import { useWindowSize } from 'react-use';
import {
  betweenWidth,
  fromWidth,
  toWidth
} from '../../utils/media-query/media-query';
import { isFirst, isLast } from '../../utils/media-query/responsive.util';
import { useMounted } from '../../utils/hooks/useMounted';

function toQueryList(breakpointWithComponents) {
  let lastBreakpoint;
  return breakpointWithComponents.map(
    ({ breakpoint, renderComponent, ssrDefault }, index, arr) => {
      let query;
      if (isFirst(index)) {
        query = toWidth(breakpoint);
      } else if (isLast(index, arr)) {
        query = fromWidth(breakpoint);
      } else {
        query = betweenWidth(lastBreakpoint, breakpoint);
      }
      lastBreakpoint = breakpoint;
      return { breakpoint, query, renderComponent, ssrDefault };
    }
  );
}

function toQueryListPromotion(breakpointWithComponents) {
  return breakpointWithComponents
    .filter(elm => elm.toBreakpoint || elm.fromBreakpoint)
    .map(({ toBreakpoint, fromBreakpoint, renderComponent, ssrDefault }) => {
      let query;
      if (toBreakpoint && !fromBreakpoint) {
        query = toWidth(toBreakpoint);
      } else if (!toBreakpoint && fromBreakpoint) {
        query = fromWidth(fromBreakpoint);
      } else {
        query = betweenWidth(fromBreakpoint, toBreakpoint);
      }
      return { query, renderComponent, ssrDefault };
    });
}

const useMediaQuery = queries => {
  if (isBrowser) {
    // check any breakpoint matched
    const foundBreakpoint = queries.find(
      query => window.matchMedia(query.query).matches
    );

    if (foundBreakpoint) {
      return foundBreakpoint;
    }
  }

  // SSR => return default SSR component
  return queries.find(query => query.ssrDefault);
};

// This component will receive list of components & breakpoints of it, on each breakpoint, it will render correct component
// It also support SSR rendering
export const ResponsiveLayout = ({ breakpointWithComponents }) => {
  // TODO: change to use callback & update foundBreakpoint when window resize, to reduce number of re-rendering
  useWindowSize();
  const mounted = useMounted();
  const queryList = React.useMemo(
    () => toQueryList(breakpointWithComponents),
    [breakpointWithComponents]
  );

  const foundBreakpoint = useMediaQuery(queryList);

  if (!mounted && !foundBreakpoint.ssrDefault && isBrowser) {
    // Should render the same as on server by default
    return queryList.find(item => item.ssrDefault).renderComponent;
  }

  return foundBreakpoint?.renderComponent;
};

export const ResponsiveLayoutFixedPromotion = ({
  breakpointWithComponents
}) => {
  // TODO: change to use callback & update foundBreakpoint when window resize, to reduce number of re-rendering
  useWindowSize();
  const queryList = React.useMemo(
    () => toQueryListPromotion(breakpointWithComponents),
    [breakpointWithComponents]
  );

  const foundBreakpoint = useMediaQuery(queryList);

  return foundBreakpoint?.renderComponent;
};

export const withSsr =
  Component =>
  ({ breakpointWithComponents }) => {
    const mounted = useMounted();
    if (!mounted) {
      // Should render the same as on server by default
      return breakpointWithComponents.find(item => item.ssrDefault)
        .renderComponent;
    }
    return <Component breakpointWithComponents={breakpointWithComponents} />;
  };

export const ResponsiveLayoutWithSsr = withSsr(ResponsiveLayoutFixedPromotion);
