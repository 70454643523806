import { graphql, useStaticQuery } from 'gatsby';
import { get } from 'lodash';

import { formatNumberToVndCurrency } from 'utils/currency';

import { countRemainPromotion } from '../../CourseLanding/Detail/countRemainPromotion';

export const usePromotionData = ({ filterBy = 'offline' }) => {
  const fromSheet = useStaticQuery(graphql`
    {
      allPromotions: allGoogleSpreadsheetDolLandingPageCmPromotion {
        nodes {
          id
          displayName
          endDate
          startDate
          remainQuantity
          interestQuantity
          value
          linkUrl
          showOn
          status
          linkText
          courseName
          primary
        }
      }
    }
  `);

  let nodes = fromSheet.allPromotions.nodes.filter(
    node => (get(node, 'status', '') || '').toLowerCase() === 'on'
  );
  switch (filterBy) {
    case 'offline': {
      nodes = nodes.filter(node =>
        get(node, 'showOn', '').toLowerCase().includes('course_n_schedule')
      );
      break;
    }
    case 'online': {
      nodes = nodes.filter(node =>
        get(node, 'showOn', '').toLowerCase().includes('online')
      );
      break;
    }
    case 'primary': {
      nodes = nodes.filter(node => {
        return (get(node, 'primary', '') || '').toLowerCase().includes('true');
      });
      break;
    }
    default:
  }

  return nodes.map(node => {
    return {
      ...node,
      value: formatNumberToVndCurrency(node.value),
      ...countRemainPromotion({
        remainQuantity: node.remainQuantity,
        startDate: node.startDate,
        endDate: node.endDate
      })
    };
  });
};
