/* eslint-disable react/prop-types */
import * as React from 'react';

const PromotionIcon = props => {
  const { prefix = new Date().getTime() } = props;
  const ids = [0, 1, 2, 3].map(idx => {
    return `${prefix}_${idx}_${Math.random()}_linear`;
  });
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 59 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M25.5828 7.6257C27.5502 5.03102 31.4509 5.03102 33.4183 7.6257L35.8755 10.8663C36.4122 11.5742 37.2891 11.9374 38.1692 11.8164L42.1981 11.2624C45.424 10.8189 48.1822 13.5771 47.7387 16.803L47.1847 20.8319C47.0637 21.712 47.4269 22.5889 48.1348 23.1256L51.3754 25.5828C53.9701 27.5502 53.9701 31.4509 51.3754 33.4183L48.1348 35.8755C47.4269 36.4122 47.0637 37.2891 47.1847 38.1692L47.7387 42.1981C48.1822 45.424 45.424 48.1822 42.1981 47.7387L38.1692 47.1847C37.2891 47.0637 36.4122 47.4269 35.8755 48.1348L33.4183 51.3754C31.4509 53.9701 27.5502 53.9701 25.5828 51.3754L23.1256 48.1348C22.5889 47.4269 21.712 47.0637 20.8319 47.1847L16.803 47.7387C13.5771 48.1822 10.8189 45.424 11.2624 42.1981L11.8164 38.1692C11.9374 37.2891 11.5741 36.4122 10.8663 35.8755L7.6257 33.4183C5.03102 31.4509 5.03102 27.5502 7.6257 25.5828L10.8663 23.1256C11.5742 22.5889 11.9374 21.712 11.8164 20.8319L11.2624 16.803C10.8189 13.5771 13.5771 10.8189 16.803 11.2624L20.8319 11.8164C21.712 11.9374 22.5889 11.5741 23.1256 10.8663L25.5828 7.6257Z"
        fill={`url(#${ids[0]})`}
      />
      <path
        d="M36.155 26.3216L26.3216 36.155C25.3616 37.115 23.8051 37.115 22.845 36.155C21.885 35.1949 21.885 33.6384 22.845 32.6784L32.6784 22.845C33.6384 21.885 35.1949 21.885 36.155 22.845C37.115 23.8051 37.115 25.3616 36.155 26.3216Z"
        fill={`url(#${ids[1]})`}
      />
      <path
        d="M27.0417 24.5833C27.0417 25.941 25.941 27.0417 24.5833 27.0417C23.2256 27.0417 22.125 25.941 22.125 24.5833C22.125 23.2256 23.2256 22.125 24.5833 22.125C25.941 22.125 27.0417 23.2256 27.0417 24.5833Z"
        fill={`url(#${ids[2]})`}
      />
      <path
        d="M31.9583 34.4167C31.9583 35.7744 33.059 36.875 34.4167 36.875C35.7744 36.875 36.875 35.7744 36.875 34.4167C36.875 33.059 35.7744 31.9583 34.4167 31.9583C33.059 31.9583 31.9583 33.059 31.9583 34.4167Z"
        fill={`url(#${ids[3]})`}
      />
      <defs>
        <linearGradient
          id={ids[0]}
          x1="37.3247"
          y1="39.5"
          x2="12.5724"
          y2="3.63887"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D14242" />
          <stop offset="1" stopColor="#F18989" />
        </linearGradient>
        <linearGradient
          id={ids[1]}
          x1="35"
          y1="29"
          x2="18.4676"
          y2="38.6708"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id={ids[2]}
          x1="35"
          y1="29"
          x2="18.4676"
          y2="38.6708"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id={ids[3]}
          x1="35"
          y1="29"
          x2="18.4676"
          y2="38.6708"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

PromotionIcon.propTypes = {};

PromotionIcon.defaultProps = {};

export default React.memo(PromotionIcon);
